export default class BaseModel {
  attributes = {};

  constructor(props, hidden = []) {
    this.attributes = props;
    Object.keys(props).forEach((key) => {
      if (!hidden.includes(key)) {
        this[key] = props[key];
      }
    });
  }

  get(attribute, defaultValue = null) {
    return this.attributes[attribute] || defaultValue;
  }
}
