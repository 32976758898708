import { ref } from 'vue';

const stack = ref([]);

function useStackModal() {
  function enableScroll() {
    document.documentElement.classList.remove('no-scroll');
  }

  function disableScroll() {
    document.documentElement.classList.add('no-scroll');
  }

  function open(component, props = {}) {
    stack.value.push({ component, props });
    disableScroll();
  }

  function close() {
    if (stack.value.length > 0) {
      stack.value.pop();

      if (!stack.value.length) {
        enableScroll();
      }
    }
  }

  function closeAll() {
    stack.value = [];
    enableScroll();
  }

  return {
    open,
    close,
    closeAll,
    enableScroll,
    disableScroll,
    stack,
  };
}

export { useStackModal };
