<template>
  <div
    data-cy="cmp-modal"
    :class="rootClass"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <div
      class="content"
      :class="{ 'scale-width': scaleWidth }"
    >
      <div
        v-if="withHeader"
        class="header"
        :class="{ 'header--simple': simple }"
      >
        <span class="header__title">{{ title }}</span>
        <span class="header__subtitle">{{ subtitle }}</span>
      </div>

      <div
        v-if="withHeader && !noClose"
        class="header__close"
        @click="$emit('close')"
      >
        <Icon
          name="times"
          class="header__close-icon"
          :size="20"
        />
      </div>

      <div
        class="inner"
        :class="{ 'inner--simple': simple }"
      >
        <h2
          v-if="hasTitle"
          class="title"
        >
          <slot name="title" />
        </h2>
        <slot />
        <div
          v-if="hasActions && !simple"
          class="actions"
        >
          <slot name="actions" />
        </div>
      </div>

      <div
        v-if="simple && $slots.actions"
        class="actions--simple"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    open: { type: Boolean, required: true },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    withHeader: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    noClose: { type: Boolean, default: false },
    scaleWidth: { type: Boolean, default: false },
  },
  emits: ['close'],
  data() {
    return {
      mouseDownInElement: false,
    };
  },
  computed: {
    rootClass() {
      return {
        modal: true,
        'modal--open': this.open,
        'modal--compact': this.compact,
      };
    },
    hasActions() {
      return this.$slots.actions;
    },
    hasTitle() {
      return this.$slots.title;
    },
  },
  methods: {
    handleRootClick(e) {
      if (e.target.classList.contains('modal')) {
        this.$emit('close');
      }
    },
    onMouseUp(e) {
      if (this.mouseDownInElement) {
        this.mouseDownInElement = false;
        this.handleRootClick(e);
      }
    },
    onMouseDown(e) {
      if (e.target.classList.contains('modal')) {
        this.mouseDownInElement = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";
$open: null;
$compact: null;

.modal {
  display: none;
  overflow-y: auto;
  padding: 100px 0;
  height: 100vh;
  background: rgba($color-brand, .4);
  z-index: 10000;

  &--open {
    $open: & !global;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }

  &--compact {
    $compact: & !global;
  }
}

.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: $color-grey200;
  border-radius: 10px 10px 0 0;

  &--simple {
    background: none;
    justify-content: flex-start;
    height: auto;
    padding: 24px 24px 0px 24px;

    .header__title {
      @include header-3-new-semi-bold;
      color: $color-grey-tinted-900;
    }

    .header__subtitle {
      @include header-3-new-semi-bold;
      color: $color-grey-tinted-500;
    }
  }

  &__title,
  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #222;
  }

  &__subtitle {
    opacity: .5;
    margin-left: 8px;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    border-radius: 8px;

    &:hover {
      background-color: $color-grey-tinted-100;
    }

    &-icon {
      transition: all .2s;
      color: $color-grey-tinted-500;
    }

    &:hover &-icon {
      color: $color-grey-tinted-900;
      transform: scale(1.2);
    }
  }
}

.scale-width {
  min-width: 600px;
  width: auto !important;
}

.content {
  background: #fff;
  position: relative;
  width: 600px;
  z-index: 2;
  border-radius: 10px;
  margin: auto;
  flex-grow: 0;

  @include small-down {
    width: 84%;
  }
}

.inner {
  padding: 32px;

  &--simple {
    padding: 16px 24px 24px 24px;
  }

  #{$compact} & {
    padding: 20px;
  }
}

.title {
  font-size: 24px;
}

.actions {
  margin-top: 32px;

  &--simple {
    border-top: 1px solid $color-grey-tinted-200;
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}
</style>
