<template>
  <div
    data-cy="app"
    id="app"
    :class="{
      'is-impersonating': isImpersonating,
    }"
  >
    <div>
      <router-view />
      <ToasterRoot />
      <SubAccountIntroModal />
      <StackModalWrapper />
    </div>
    <OfficehubImpersonationBar
      v-if="isImpersonating && me?.customer?.name"
      :customer-name="me.customer.name"
      @close="leaveImpersonationModeLocal"
      :show-loading="leaveImpersonationModeLoading"
    />
  </div>
</template>

<script>
import {
  StackModalWrapper,
  OfficehubImpersonationBar,
} from '@officeguru/components-vue3';

window.process = import.meta;

import './style/main.scss';
import { registerEmitter } from '@officeguru/webapp-shared/src/helpers/emitter';
import api from '@/store/plugins/api';
import { getCurrentInstance } from 'vue';
import Bugsnag from '@bugsnag/js';
import { posthog } from './plugins/posthog';
import ToasterRoot from '@/components/toaster-root.vue';
import SubAccountIntroModal from '@/components/officehub/sub-account-intro-modal.vue';
import { useOfficehub } from '@/composables/use-officehub';

const { leaveImpersonationMode } = useOfficehub();

export default {
  name: 'App',
  components: {
    StackModalWrapper,
    ToasterRoot,
    SubAccountIntroModal,
    OfficehubImpersonationBar,
  },
  setup() {
    return {
      Bugsnag,
    };
  },
  data() {
    return {
      leaveImpersonationModeLoading: false,
    };
  },
  created() {
    getCurrentInstance().appContext.config.globalProperties.$posthog = posthog;
  },
  provide: {
    api,
  },
  methods: {
    async leaveImpersonationModeLocal() {
      if (this.leaveImpersonationModeLoading) return;

      this.leaveImpersonationModeLoading = true;

      try {
        await leaveImpersonationMode();
      } catch (e) {
        this.leaveImpersonationModeLoading = false;
        throw e;
      }
    },
  },
  beforeCreate() {
    registerEmitter();
  },
  computed: {
    me() {
      return this.$store.getters['users/me'];
    },
    isImpersonating() {
      return this.me?.is_impersonating || false;
    },
  },
};
</script>

<style lang="scss">
@import '@/style/main';

.is-impersonating {
  .toast-controller {
    top: 56px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.mobile-only {
  display: none !important;

  @include small-down {
    display: block !important;
  }
}

.mobile-hide {
  @include small-down {
    display: none !important;
  }
}

@include small-down {
  .page,
  .page-v2 {
    margin-top: 50px !important;
  }
}
</style>
