import { createI18n } from 'vue-i18n';
import da from './locales/da.json';
import en from './locales/en.json';

const FALLBACK_LOCALE = 'en';

const i18n = createI18n({
  locale: 'da',
  fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    da,
    en,
  },
  silentTranslationWarn: false,
  warnHtmlInMessage: 'off',
  legacy: false,
});

export default i18n;
export { FALLBACK_LOCALE };
